/* .questionInputContainer {
  width: 95%;
  left: 2.5%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border-radius: 14px;
  fill: #fff;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.17));
} */
.mquestionInputContainer {
  /* height: 40px; */
  width: 90%;
  left: 2.5%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border-radius: 14px;
  fill: #fff;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.17));
}
.audioInput {
  margin-top: 22px;
  margin-right: 9px;
}
/* .questionInputTextArea {
  width: 100%;
  line-height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 3px;
  padding: 3px;
  padding-left: 28px;
  padding-top: 5px;
  font-family: "HM Sans", sans-serif !important;
} */
.fieldGroup-145 {
  width: 93% !important;
}

/* .questionInputSendButtonContainer {
  position: absolute;
  right: -38px;
  bottom: 27px;
  margin-right: -76px;
} */
.mquestionInputSendButtonContainer {
  height: 40px;
  margin-right: -62px;
}
.questionInputSendButton {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -332px -201px;
  width: 58px;
  height: 58px;
  display: inline-flex;
  margin-left: 8px;
}

.questionInputSendButtonDisabled {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -247px -201px;
  width: 58px;
  height: 58px;
  display: inline-flex;
  cursor: not-allowed;
  margin-left: 8px;
}
.mquestionInputSendButtonDisabled {
  background: none;
  color: #424242;
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -247px -201px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  margin-right: 25px;
  margin-bottom: -25px;
  zoom: 0.8;
  opacity: 0.6; /* Example: reduce opacity for a faded look */
  cursor: not-allowed;
}

.questionInputBottomBorder {
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0%;
  bottom: 0%;
  background: radial-gradient(
    106.04% 106.06% at 100.1% 90.19%,
    #0f6cbd 33.63%,
    #8dddd8 100%
  );
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.questionInputOptionsButton {
  cursor: pointer;
  width: 27px;
  height: 30px;
}
.soundwave {
  position: absolute;
  top: 53%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}
.soundwaveright {
  position: absolute;
  top: 53%;
  left: 58%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.micOffSpriteIcon {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -85px -45px;
  height: 19px;
  width: 17px;
  display: inline-flex;
  align-items: center;
}
.micOffSpriteIconDisabled {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -132px -45px;
  height: 19px;
  width: 17px;
  display: inline-flex;
  align-items: center;

  cursor: not-allowed;
}
.micOnSpriteIcon {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -38px -45px;
  height: 19px;
  display: inline-flex;
  align-items: center;
  margin-top: 23px;
}
/* Styles for devices with a maximum width of 767 pixels (typical mobile devices) */
@media only screen and (max-width: 767px) {
  /* .questionInputContainer {
    width: 83%;
  } */

  .micOnSpriteIcon {
    width: 17px;
    margin-right: 26px;
  }
  .soundwaveright {
    width: 50px;
    height: 25px;
  }
  .soundwave {
    width: 50px;
    height: 25px;
  }
}

/* Styles for devices with a minimum width of 768 pixels and a maximum width of 1024 pixels (typical iPads) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* .questionInputContainer {
    width: 95%;
  } */

  .micOnSpriteIcon {
    width: 14px;
    margin-right: 41px;
  }
  .soundwaveright {
    width: 100px;
    height: 50px;
  }
  .soundwave {
    width: 100px;
    height: 50px;
  }
}

/* Styles for devices with a minimum width of 1025 pixels (typical desktop devices) */
@media only screen and (min-width: 1025px) {
  /* .questionInputContainer {
    width: 95%;
  } */

  .micOnSpriteIcon {
    width: 14px;
    margin-right: 41px;
  }
  .soundwaveright {
    width: 100px;
    height: 50px;
  }
  .soundwave {
    width: 100px;
    height: 50px;
  }
}
.questionInputContainer {
  height: 57px;
  width: 100%;
}

.IpCnt {
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 12px 0px #0000002b;
  border-radius: 12px;
}
/* // 67 */
@media screen and (min-width: 1821px) and (max-width: 3000px) {
  .IpCnt {
    width: 93%;
  }
}
/* // 75% */
@media screen and (min-width: 1701px) and (max-width: 1820px) {
  .IpCnt {
    width: 93%;
  }
}
/* // 80% */
@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .IpCnt {
    width: 93%;
  }
}
/* // 90 % */
@media screen and (min-width: 1401px) and (max-width: 1599px) {
  .IpCnt {
    width: 93%;
  }
}
/* // 100% */
@media screen and (min-width: 1264px) and (max-width: 1400px) {
  .IpCnt {
    width: 100%;
  }
}
