$form-range-thumb-bg: black !default;
body {
  font-family: "HM Sans", sans-serif !important;
  /* Replace 'Your Default Font' with the actual font you want to use */
}
.defaultFontFamily,
textarea {
  font-family: "HM Sans", sans-serif !important;
}
.answerText th,
.answerText td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
// Here you can add other styles
.background {
  width: 100%;
  // background-image: url("../images/background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.wrapper {
  // background-image: url('../assets/images/homebg.png');
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  // background: radial-gradient(
  //     70.83% 85.87% at 34.46% 29.17%,
  //     #435268 0%,
  //     #1d3551 36.98%,
  //     #0d2337 66.84%,
  //     #1d3551 100%
  //   ),
  //   #ffffff;
  // background: #e4e4e4;
}
.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}
.btn-login-button {
  border-radius: 0;
  border: none;
  background: #ffffff;
  color: #000000;
  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background: #d4d4d4;
    color: #000000;
  }
}
.bg-login-cardgroup {
  border: 6px solid;
  border-image: linear-gradient(
      to right,
      #d6c6f6,
      #f6d2ef,
      #fcc5da,
      #fcdcd2,
      #ffe5ca
    )
    1;
  border-image-slice: 1;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.bg-login-cardgroup:hover {
  transform: perspective(1000px) rotateY(0deg);
}
.bg-login-cardgroup {
  // background-color: #f5f5f5 !important;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
  // border-radius: 8px; /* Rounded corners */
  /* Additional card styling properties */
}
.bg-login {
  border: none !important;
  border-radius: 20px;
  background: #13131312;
  backdrop-filter: blur(10px);
}
.header {
  background-color: #000000;
  border-bottom: 1px solid #595959 !important;
  padding: 0px !important;
  margin-top: -2px;
}

.sidebar {
  background-color: #0d1927 !important;
  border: none !important;
}
.breadcrumb-item::before {
  content: "" !important;
}
.footer {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  height: 3.01rem !important;
}
.app-logo {
  width: 200px;
  padding: 20px;
}

.breadcrumb-item.active {
  font-size: 24px;
  color: #ffffff;
}
.logout-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.colvaluewidth {
  width: 26.2%;
}
.bg-row-transparent {
  background-color: #ffffff;
  color: #2c2c2c;
  border: 1px solid #cccaca;
  text-align: center;
}

.round-circle {
  display: inline-block;
  margin-bottom: -12px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  margin-left: 10px;
}

.tile-element {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #fb7358;
  border-radius: 6px;
  border-left: 15px solid #fb7358;
  &.yellow {
    border: 2px solid #febe00;
    border-left: 15px solid #febe00;
  }
  &.green {
    border: 2px solid #1eb488;
    border-left: 15px solid #1eb488;
  }
}
.guage-alignment {
  padding-inline-start: 5px;
}
.customCard {
  .cardCount {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #555555;
  }
  .cardTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    color: #929292;
  }
  .cardPercentage {
    font-size: 13px;
    .percentageIcon {
      width: 24px;
      height: 18.17px;
      display: inline-block;
      vertical-align: sub;
      &.upIcon {
        background: url(../images/up-icon.png);
      }
      &.downIcon {
        background: url(../images/down-icon.png);
      }
    }
    .percentageText {
      color: #555555;
      &.upIconText {
        color: #579c21;
      }
      &.downIconText {
        color: #e44659;
      }
    }
  }
  .cardIcon {
    width: 60px;
    height: 70.02px;
    float: right;
    img {
      width: 50px;
    }
  }
}
.leaflet-container {
  width: 100% !important;
}

.leaflet-bottom {
  display: none !important;
}
/* For WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* width of the scrollbar */
  height: 10px;
}
.main-98 {
  width: 93% !important;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-primary-100, #222);
  border-radius: 5px; /* roundness of the scrollbar thumb */
}

// ::-webkit-scrollbar-thumb:hover {
//   background: linear-gradient(
//     180deg,
//     #79abc2 0%,
//     #5788ac 49.48%,
//     #5070a4 98.44%
//   ); /* color of the scrollbar thumb on hover */
// }

/* For Firefox */
/* Note: Firefox currently does not support styling the scrollbar track */
/* However, you can still style the scrollbar thumb */
::-moz-scrollbar-thumb {
  // background: linear-gradient(
  //   180deg,
  //   #79abc2 0%,
  //   #5788ac 49.48%,
  //   #5070a4 98.44%
  // ); /* color of the scrollbar thumb */
  border-radius: 5px; /* roundness of the scrollbar thumb */
  background-color: var(--primary-primary-100, #222);
}

// ::-moz-scrollbar-thumb:hover {
//   background: linear-gradient(
//     180deg,
//     #79abc2 0%,
//     #5788ac 49.48%,
//     #5070a4 98.44%
//   ); /* color of the scrollbar thumb on hover */
// }

.HistoryIcon {
  background-position: -182px -49px !important;
  width: 15px;
  height: 15px;
  margin: 4px;
}
.VideosIcon {
  background-position: -230px -49px !important;
  width: 17px;
  height: 15px;
  margin: 4px;
}
.FilesIcon {
  background-position: -280px -49px !important;
  width: 12px;
  height: 15px;
  margin: 4px;
}
.LinksIcon {
  background-position: -334px -49px !important;
  width: 21px;
  height: 15px;
  margin: 4px;
}
.InlineDisplayProperty {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.activeRecommendBtn {
  background-color: #dae7e2 !important;
  color: #3a3a3a !important;
  border: 2px solid #cce0d9 !important;
  border-radius: 38px !important;
  box-shadow: none !important;
  margin-right: 12px;
  padding: 3px 12px;
}
.recommendBtn,
.recommendBtn:focus-visible,
.recommendBtn:active {
  background: #ffffff !important;
  color: #3a3a3a !important;
  border: 2px solid #cce0d9 !important;
  border-radius: 38px !important;
  box-shadow: none !important;
  margin-right: 8px;
  padding: 3px 12px;
}
.promtBtn,
.promtBtn:focus-visible,
.promtBtn:active {
  background: #53655f !important;
  color: #ffffff !important;
  border: 2px solid #cce0d9 !important;
  border-radius: 38px !important;
  box-shadow: none !important;
  margin-right: 8px;
  padding: 3px 12px;
  margin-bottom: 4px;
}

.promtBtn:hover {
  background: #586d66 !important;
}
.videoModal .modal-content {
  overflow: initial !important;
  border: none !important;
  margin: 0px 25px;
}
.typingAnime {
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 15px 0px;
}

.typingAnime > div {
  width: 14px;
  height: 14px;
  background-color: #cce0d9;
  margin: 0 2px 0 2px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.typingAnime .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.typingAnime .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.wordIcon {
  background-position: -38px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.pdfIcon {
  background-position: -85px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.excelIcon {
  background-position: -134px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.pptIcon {
  background-position: -182px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.spriteUrl {
  background: url(../images/svgs/ai-companion-sprite.svg) no-repeat;
}
.Answer_answerText__fK2eo p {
  margin-bottom: 0;
}
.answerText p {
  margin-bottom: 0px;
}
.videoModal {
  .modal.show .modal-dialog {
    background-color: #ffffff;
    margin: 0px;
  }
  .modal-content {
    border: none;
  }
  .modal-dialog.modal-dialog-centered {
    width: 100%;
    height: 100%;
  }
}
.chatPopover {
  .popover-arrow {
    display: none;
  }
  .popover-body {
    padding: 8px;
  }
}
.popover.bs-popover-top.fade.show.undefined.chatPopover {
  left: -2% !important;
}
.DownloadIcon {
  background-position: -38px -139px !important;
  width: 15px;
  height: 13px;
  display: inline-flex;
  margin: 4px;
  cursor: pointer;
  padding: 0px 12px !important;
}
.chatPopoverRow {
  border-bottom: 1px solid #c6ddd4;
}
.alignProp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.sampleQueTT {
  .tooltip-arrow {
    transform: (64px, 29px);
  }
}
.sampleQuePopover.popover {
  max-width: 400px;
  min-width: 380px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  min-width: 160px;
  z-index: 1;
}

// 100%
@media screen and (min-width: 1264px) and (max-width: 1400px) {
  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 16% !important;
    max-width: 350px;
  }
}
// 67
@media screen and (min-width: 2013px) and (max-width: 3100px) {
  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -48% !important;
    left: 23% !important;
    max-width: 400px;
  }
}
// 90 %
@media screen and (min-width: 1401px) and (max-width: 1599px) {
  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 18% !important;
    max-width: 350px;
  }
}
// 80%
@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 18% !important;
    max-width: 400px;
  }
}
// 75%
@media screen and (min-width: 1751px) and (max-width: 1820px) {
  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 23% !important;
    max-width: 400px;
  }
}
.dropdown-content.HAI {
  top: -39%;
  left: 20%;
}
.dropdown-content.Business {
  top: -39%;
  left: 13%;
}
.dropdown-content.Store {
  top: -39%;
  left: 12%;
}
.dropdown-content.Operational {
  top: -39%;
  left: 18%;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #e6e6e6;
}
.mob-MoreInfo {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  color: #000;
  .dd-menu {
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    max-width: 90vw;
    min-width: 80vw;
    overflow: hidden;
  }

  .sysSampleQue {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    cursor: pointer;
  }

  .sysSampleQue:hover {
    background-color: #fff;
    color: #000;
  }

  .lineSeperator {
    border-bottom: 1px solid #d1d1d1;
  }
  .dropdown-toggle {
    background: transparent;
    border: none;
    height: 20px;

    &::after {
      display: none;
    }
  }

  .dropdown-item {
    text-wrap: wrap;

    &:hover {
      background: #fff;
      color: #000;
    }
    &:focus,
    &:active,
    &:focus-visible {
      background: #ebebeb;
      color: black;
    }
  }
  .mob-MoreInfo-icon {
    background: url("../images/svgs/aicompanion-mobile-sprite.svg") no-repeat -354px -60px;
    width: 4px;
    height: 16px;
    display: block;
  }
}
.citationPanelContent p {
  overflow: scroll;
}
.activeSystem {
  box-shadow: 0px 0px 12px 0px #4f7d6c !important;
}
.moreInfo-ChatWindow {
  .dropdown-menu {
    min-width: 40vw;
  }

  .dropdown-toggle {
    background: transparent;
    border: none;
    height: auto;
    padding: 2px;

    &::after {
      display: none;
    }

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background: transparent;
      color: black;
    }
  }

  .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: #fff;
      color: #000;
    }

    &:focus,
    &:active,
    &:focus-visible {
      background: #ebebeb;
      color: black;
    }
  }
}
.GramerlyPopDiv {
  .popover-body {
    background: #000000b8;
    box-shadow: 0px 0px 6px 0px #00000040;
    color: #ffffff;
  }
}
.rotate-90 {
  transform: rotate(90deg);
}
.moreInfo-ChatWindow a {
  padding: 4px;
}
.grammerlycheckbox {
  .form-check-input:checked {
    background-color: #000 !important;
    border-color: #fff !important;
  }
  .form-check-input:focus {
    box-shadow: none !important;
  }
}

.ant-dropdown-menu-title-content {
  color: #fff;
}
.belowIcon {
  background-position: -387px -133px !important;
  width: 18px;
  height: 18px;
  display: inline-flex;
  margin-left: 8px;
}
.ant-dropdown-menu {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.ant-dropdown-arrow::after {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.accordion-button {
  padding: 0px !important;
  background: white !important;
  border: 2px solid #cce0d9;
  padding-right: 23px !important;
}
.accordion {
  padding-bottom: 10px !important;
}
.nav-link {
  color: black;
}
.accordion-without-arrow .accordion-button::after {
  display: none !important; /* Hide the arrow */
}
.sorryDiv {
  padding: 0px !important;
  background: white !important;
  border: 3px solid #cce0d9;
  padding-right: 23px !important;
  border-radius: 5px !important;
}
/* Track (background) color */
.chakra-slider__track {
  background-color: black;
}

/* Thumb (slider handle) color */
.chakra-slider__thumb {
  background-color: black;
}

/* Stepper button color */
.chakra-numberinput__stepper {
  color: black; /* Change the color of the stepper buttons */
}

input[type="range"] {
  accent-color: #000 !important; /* Or any desired color */
}
input[type="range"] {
  -webkit-appearance: none; /* Removes default styles */
  appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #ddd; /* Color for the track */
}

input[type="range"]::-webkit-slider-thumb {
  background: #646464; /* Color for the thumb */
}
// .app{
//     background-image: url(../../assets/images/WaveBackground.svg) !important;
//     background-size: cover !important;
//     background-repeat: no-repeat !important;
//     background-position: center center !important;
//     background-attachment: fixed !important;
// }
.app.dark {
  background: #000;
  color: #d4d4d4 !important;
  height: 90vh;
  .home_subtitle_right {
    color: #d4d4d4 !important;
  }
  .home_getstarted_btn {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 18px;
    background: #fff !important;
    color: #000000 !important;
    border-radius: 0px !important;
    border: 0px !important;
  }
  .msgIcon {
    background-position: -357px -68px !important;
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  .AnswerTexButton,
  .recommendBtn,
  .activeRecommendBtn {
    background: #000000 !important;
    color: #fff !important;
    border: 2px solid #434343 !important
    ;
  }
  .btnName,
  .historyHeading,
  .helpmessage {
    color: #fff !important;
  }
  .sampleQuestionCard {
    background-color: #262626;
    color: #ffffff;
    box-shadow: 0px 0px 6px 0px #00000040;
  }
  .spriteUrl {
    background: url("../../assets/images/svgs/ai-companion-dark-sprite.svg")
      no-repeat;
  }
  .genAIChatIcon {
    background: url("../../assets/images/Chat-Dark-logo.svg") no-repeat;
    width: 129px;
    height: 106px;
    position: absolute;
    bottom: 25px;
    right: 10px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  .chat-UserName {
    background: #000000;
    border: 3px solid #999999;
  }
  .HistoryIcon {
    background-position: -111px -23px !important;
    width: 15px;
    height: 15px;
    margin: 4px;
    color: #ffffff;
  }
  .gramerlyicon {
    background: url("../../assets/images/svgs/ai-companion-dark-sprite.svg")
      no-repeat -226px -68px;
    width: 4px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  .Speaker228Filled,
  .SpeakerMute28Regular,
  .disclaimer,
  .stopGeneratingText,
  .displayProperty {
    color: #ffffff !important;
  }
  .chatMessageUserMessage {
    background: #2b2b2b;
    color: #ffffff;
  }
  .historyContainer {
    background: #010101;
    color: #ffffff;
  }
  .titleIcon {
    background: url("../../assets/images/svgs/ai-companion-dark-sprite.svg")
      no-repeat -23px -13px;
    width: 56px;
    height: 56px;
    display: inline-flex;
    align-items: center;
    margin-right: 13px;
  }
  .chatLikeIcon {
    background-position: -257px -68px !important;
    width: 15px;
    height: 15px;
    display: inline-flex;
    margin-right: 8px;
    cursor: pointer;
  }
  .likeFilledIcon {
    background-position: -111px -68px !important;
    width: 15px;
    height: 15px;
    display: inline-flex;
    margin-right: 8px;
    cursor: pointer;
  }
  .disLikeFilledIcon {
    background-position: -159px -68px !important;
    width: 16px;
    height: 15px;
    display: inline-flex;
    margin-right: 8px;
    cursor: pointer;
  }
  .chatDislikeIcon {
    background-position: -300px -68px !important;
    width: 16px;
    height: 15px;
    display: inline-flex;
    margin-right: 8px;
    cursor: pointer;
  }
  .DownloadIcon {
    background-position: -452px -69px !important;
    width: 15px;
    height: 13px;
    display: inline-flex;
    margin-right: 8px;
    cursor: pointer;
  }
  .chatCopyIcon {
    background-position: -209px -23px !important;
    width: 13px;
    height: 15px;
    display: inline-flex;
    margin-right: 8px;
    cursor: pointer;
  }
  .closeIcon {
    background-position: -497px -67px !important;
    width: 18px;
    height: 18px;
    margin: 4px;
    cursor: pointer;
  }
  .helpmeicon {
    background: url("../../assets/images/svgs/ai-companion-dark-sprite.svg")
      no-repeat -23px -13px;
    width: 56px;
    height: 56px;
    display: inline-flex;
    align-items: center;
  }
  .IpCnt {
    background: #ffffff;
  }
  .accordion-button {
    border: none;
  }
  .aiSidebar {
    background-color: #242424;
  }
  .dropbtn {
    background-color: #262626 !important;
    box-shadow: 0px 0px 6px 0px #00000040 !important;
    color: #fff !important;
  }
  .sample_question_description,
  .sample_question_title {
    color: #fff !important;
  }
  .selectedIndex_Row {
    background-color: #4d4d4d;
    color: #ffffff;
    margin: 0px !important;
  }
  .ant-dropdown-menu-title-content {
    color: #000000;
  }
  .ant-dropdown-menu {
    background-color: #ffffffb2 !important;
  }
  .ant-dropdown-arrow::after {
    background-color: #ffffffb2 !important;
  }
}
.darkSingleQue {
  color: #000000;
}
.darkMenuProps {
  background-color: #ffffffb2 !important;
}
.darkgramerlyPopover {
  background: #ffffffb2 !important;
}
.darkGrammerlycheckbox {
  color: #000 !important;
}
@media screen and (min-width: 2000px) and (max-width: 3000px) {
  .app.dark {
    height: 93.4vh;
  }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .app.dark {
    height: 92.5vh;
  }
}
@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .app.dark {
    height: 93vh;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .app.dark {
    height: 92vh;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1599px) {
  .app.dark {
    height: 91vh;
  }
}
@media screen and (min-width: 1264px) and (max-width: 1400px) {
  .app.dark {
    height: 90vh;
  }
}