.sampleQueDes {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(120, 120, 120, 1);
}
.mobGriditem {
  padding: 10px !important;
}
.sampQueTitle {
  font-size: 15px;
  margin-bottom: 4px;
}
.mobCitation {
  width: 100% !important;
  background-color: #e7ecea !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px;
  gap: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  flex: auto;
  order: 0;
  align-self: stretch;
  flex-grow: 0.3;
  overflow-y: scroll;
  max-height: calc(100vh - 100px);
}
.panelContent {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}
.citationPanelContent p {
  overflow: scroll;
}
.mobhdrcnt {
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 8px;
}
.mTitleCnt{
  margin-top:5px;
  display:flex; 
  flex-direction:column;
  align-items:center;
}
.mTitleImg{
  width:30px;
  height:30px;
  margin-bottom: 2px;
}
.mTitleText{
  font-size: 16px;
  width: 310px;
  display: flex;
  justify-content: center;
line-height: 38px;
letter-spacing: 0em;
text-align: left;
height: 38px;
margin-bottom: 0;
}
