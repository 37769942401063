.headertitle {
  color: white;
  font-size: 22px;
}

.ChatDropDownToggle,
.ChatDropDownTo:hover {
  color: white !important;
  background-color: #000 !important;
  border-color: #000 !important;
}
.ChatDropDownTo:hover {
  color: white !important;
  background-color: #000 !important;
  border-color: #000 !important;
}
.PageTitle {
  color: white !important;
}
.custom-modal {
  max-height: 80vh; /* Adjust this value as needed */
  overflow-y: auto;
  font-size: 14px;
}

.custom-modal-body {
  max-height: 60vh; /* Adjust this value as needed */
  overflow-y: auto;
}
.custom-input-group {
  display: flex;
  align-items: center;
}

.custom-input-group-text {
  min-width: 60px; /* Adjust the width as needed */
  text-align: center;
}

.chakra-slider__track {
  background-color: black !important;  /* Change to black */
}

/* Change thumb color */
.chakra-slider__thumb {
  background-color: black !important; /* Change to black */
}
