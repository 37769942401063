.aiSidebar {
  background-color: #e7ecea;
  min-height: 87vh;
  margin: 10px 10px 0px 0px;
  border-radius: 15px;
  padding: 15px;
}
.recommendationHeading {
  margin: 0px 0px 18px 0px !important;
  padding: 12px 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3a3a;
  border-bottom: 1px solid #c8c8c8;
}
.displayProperty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.spriteUrl {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat;
}
.mobSpriteUrl {
  background: url("../../assets/images/svgs/aicompanion-mobile-sprite.svg")
    no-repeat;
}
.closeIcon {
  background-position: -134px -136px !important;
  width: 12px;
  height: 12px;
  margin: 4px;
  cursor: pointer;
}

.fileList {
  background-color: #ffffff;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 400;
  padding: 16px 18px;
  border-radius: 7px;
}
.downloadIcon {
  display: inline-flex;
  background-position: -391px -49px !important;
  width: 12px;
  height: 15px;
  margin-right: 16px;
  cursor: pointer;
}
.shareIcon {
  display: inline-flex;
  background-position: -438px -49px !important;
  width: 14px;
  height: 15px;
  cursor: pointer;
}

.historyList {
  color: #787878;
  padding: 0px;
}
.historyHeading {
  padding: 8px 18px;
}
.historyContainer {
  margin-bottom: 10px;
}
.recomDataContainer {
  margin: 0px;
  max-height: 78vh;
  overflow: auto;
}
.imageCard {
  border-radius: 10px;
  border: 4px solid #fff;
  background: lightgray 50% / cover no-repeat;
  margin-bottom: 20px;
}
.playIcon {
  background-position: -38px -185px !important;
  width: 91px;
  height: 91px;
  cursor: pointer;
}
.modalCloseIcon {
  display: flex;
  background-position: -272px -90px !important;
  width: 28px;
  height: 28px;
}
.ModalCloseBtn {
  cursor: pointer;
  position: absolute !important;
  z-index: 1;
  right: -10px !important;
  top: -9px;
}
.modalBox {
  border-radius: 10px;
  border: 12px solid #d4d4d4;
  padding: 0px;
}
.rawVideoImg {
  border-radius: 4px;
}
.rawVideo {
  max-width: 100%;
  max-height: 100%;
  display: flex;
}
.chatLikeIcon {
  background-position: -390px -96px !important;
  width: 15px;
  height: 15px;
  display: inline-flex;
  margin-right: 8px;
  cursor: pointer;
}
.likeFilledIcon {
  background-position: -229px -133px !important;
  width: 15px;
  height: 15px;
  display: inline-flex;
  margin-right: 8px;
  cursor: pointer;
}
.disLikeFilledIcon {
  background-position: -277px -133px !important;
  width: 16px;
  height: 15px;
  display: inline-flex;
  margin-right: 8px;
  cursor: pointer;
}
.chatDislikeIcon {
  background-position: -438px -96px !important;
  width: 15px;
  height: 15px;
  display: inline-flex;
  margin-right: 8px;
  cursor: pointer;
}
.DownloadIcon {
  background-position: -38px -139px !important;
  width: 15px;
  height: 13px;
  display: inline-flex;
  margin-right: 8px;
  cursor: pointer;
}
.chatCopyIcon {
  background-position: -489px -49px !important;
  width: 13px;
  height: 15px;
  display: inline-flex;
  margin-right: 8px;
  cursor: pointer;
}
.actionIcons {
  float: right;
}
.recommendationCnt{
  position: relative;
}
.btnName {
  font-weight: 400;
  font-size: 14px;
}
.copyBox{
  position: absolute;
  right: 0;
  background: #242424;
  width: 149px;
height: 33px;
border-radius: 5px;
font-size: 14px;
line-height: 20.34px;
letter-spacing: 0em;
color: #fff;
display: flex;
align-items: center;
justify-content: center;

}
.mobSideBar {
  margin: 12px;
}
.chatAllOptIcon {
  background-position: -354px -60px !important;
  width: 4px;
  height: 16px;
  display: inline-flex;
  margin-right: 8px;
  cursor: pointer;
}
