.mainContainer {
}

.mainPopEffect {
  background: rgba(41, 41, 41, 0.29);
}

.chatBackground{
  background-image: url(../../assets/images/WaveBackground.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.container {
  align-items: center;
  flex: 1;
  /* display: flex; */
  flex-direction: column;
  gap: 20px;
  min-height: 80vh;
  /* background-color: #c2c2a3; */
  /* border-radius: 23px; */
  /* background: linear-gradient(
    180deg,
    #79abc2 0%,
    #6f9dbe 20.31%,
    #4e6fa5 40.63%,
    #354c86 58.85%,
    #263463 79.17%,
    #202450 100%
  ); */
  /* background-image: url(../../assets/images/chatbackgroundold.png); */
  /* background-size: cover; */
  fill: #fafafa;
  stroke-width: 1px;
  stroke: #cecece;
  /* filter: drop-shadow(9px 2px 5px rgba(0, 0, 0, 0.33)); */
}

.emailDialog {
  width: 100%;
  padding: 10px;
}

/* .chatcontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
} */
.chatbackgroundimage {
  width: 44vh;
  height: 35vh;
  /* Set the height of the container */
  background-size: cover;
  /* Adjust the background size as needed */
  background-repeat: no-repeat;
  background-image: url(../../assets/images/ChatBackgroundImg.svg);
}

.chatRoot {
  flex: 1;
  display: flex;
  height: 100%;
}

/* .chatContainer {
  height: 88vh;
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(230, 230, 210, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 85px);
  padding-bottom: 7px;
  backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
} */

.chatEmptyStateTitle {
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  line-height: 50px;
  margin-top: 36px;
  margin-bottom: 0px;
}

.chatEmptyStateSubtitle {
  margin-top: 20px;
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.01em;
}

.chatIcon {
  height: 62px;
  /* width: 62px; */
}

.chatMessageStream {
  flex-grow: 1;
  /* max-width: 1028px; */
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  padding: 0px 8px;
}

.chatMessageUser {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.chatMessageUserMessage {
  padding: 20px;
  float: right;
  border-radius: 12px;
  background: #dae7e2;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 800px;
  margin-right: 0.75rem;
}

.chatMessageGpt {
  margin-bottom: 12px;
  max-width: 80%;
  display: flex;
}

.clearChatBroom {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: absolute;
  width: 40px;
  height: 40px;
  left: -45px;
  top: 61px;
  /* border: 1px solid #d1d1d1; */
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    #e41065 0%,
    #e41065 49.48%,
    #e41065 98.44%
  ) !important;
  /* box-shadow: 1px 1px 6px 0px rgb(0 0 0 / 65%); */
  z-index: 1;
}

.AnswerTexButton {
  border-radius: 38px;
  border: 2px solid #cce0d9;
  padding: 3px 12px;
  margin-right: 8px;
}

.stopGeneratingContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 4px;
  position: absolute;
  width: 161px;
  height: 32px;
  left: calc(50% - 161px / 2 + 25.8px);
  bottom: 96px;
  border: 1px solid #d1d1d1;
  border-radius: 16px;
  margin-top: 20px;
}

.stopGeneratingIcon {
  width: 14px;
  height: 14px;
  color: #424242;
}

.stopGeneratingText {
  /* width: 103px; */
  height: 20px;
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #242424;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.citationPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px;
  gap: 8px;
  background: rgb(255 255 255 / 80%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  flex: auto;
  order: 0;
  align-self: stretch;
  flex-grow: 0.3;
  max-width: 30%;
  overflow-y: scroll;
  max-height: calc(100vh - 100px);
}

.citationPanelHeaderContainer {
  width: 100%;
}

.citationPanelHeader {
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.citationPanelDismiss {
  width: 18px;
  height: 18px;
  color: #424242;
}

.citationPanelDismiss:hover {
  background-color: #d1d1d1;
  cursor: pointer;
}

.citationPanelTitle {
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #323130;
  margin-top: 12px;
  margin-bottom: 12px;
}

.citationPanelContent {
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

a {
  padding-left: 5px;
  padding-right: 5px;
}

.tab {
  overflow: hidden;
}

.tab button {
  position: relative;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 30px;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  border: 1px solid #cccc;
  color: #676767;
  z-index: 1;
  width: 50%;
}

.tab button.active {
  z-index: 2;
  color: #ffffff;
  background-color: #009bdf;
}

.clearChatBroomSpriteIcon {
  background: url("../../assets/images/sprite.svg") no-repeat -197px -238px;
  width: 52px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: absolute;
  left: 18px;
  top: 80px;
  /* border: 1px solid #d1d1d1; */
  z-index: 1;
  zoom: 0.8;
}

.titleIcon {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -162px -197px;
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  margin-right: 13px;
  /* zoom: 0.9; */
}

.chatContainerActionTitle {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.left {
  flex: 1;
}

.indexDropDown {
  width: 33%;
  float: right;
  margin-right: -58px;
}

.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.pill-115 {
  background: #000 !important;
  border-color: transparent;
}

.sample_question_description {
  font-size: 16px;
  line-height: 23.25px;
  letter-spacing: 0em;
  text-align: left;
  color: #787878;
}

.horizontal_line {
  border-bottom: 1px solid #84a498;
  /* Adjust the color and thickness as needed */
  margin: 20px 0;
  width: 100%;
  display: block;
}

.selectedIndex_Row {
  /* width: 100%; */
  /* padding-top: 10px; */
  margin: 0px;
  background: #53655f;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.selected_index_div {
  text-align: center;
}

.selected_index {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding-left: 10px;
}

.selectedIndexIcon {
  margin-left: auto;
  padding-right: 10px;
}

.msgIcon {
  background-position: -490px -95px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.spriteUrl {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat;
}

.newConvoProperty {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 8px;
}

.btnName {
  font-weight: 400;
  font-size: 14px;

  color: #3a3a3a;
}

.griditem {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fbfbfb;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15); */
  padding: 12px;
  cursor: pointer;
}

.sample_question_title {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  color: #007b5f;
}

.chatEmptyState {
  position: fixed;
  bottom: 18%;
}

/* Styles for devices with a maximum width of 767 pixels (typical mobile devices) */
@media only screen and (max-width: 767px) {
  /* Your mobile styles here */
  .chatEmptyState {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 0px;
    padding: "0 10% 0 10%";
  }

  .horizontal_line {
    display: none;
  }

  .gridcontainer {
    display: grid;
    gap: 10px;
    /* Gap between grid items */
    width: 100%;
  }

  /* .chatInput {
    position: sticky;
    flex: 0 0 100px;
    padding: 0px !important;
    padding-left: 24px;
    padding-right: 24px;
    width: 98%;
    max-width: 1028px;
    margin-bottom: 50px;
    margin-top: 8px;
  } */
  .userMessage {
    width: 88% !important;
  }

  .chatUserNameIcon {
    width: 12% !important;
    margin-top: 9px;
  }

  .chatMessageStream {
    padding: 0px !important;
  }
}

/* Styles for devices with a minimum width of 768 pixels and a maximum width of 1024 pixels (typical iPads) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Your iPad styles here */
  .chatEmptyState {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 0px;
  }

  /* .chatInput {
    position: sticky;
    flex: 0 0 100px;
    padding-top: 12px;
    padding-bottom: 2px;
    padding-left: 24px;
    padding-right: 24px;
    width: 98%;
    max-width: 1028px; 
     margin-bottom: 50px;
    margin-top: 8px;
  } */
  .chatMessageStream {
    padding-left: 24px;
    padding-right: 24px;
  }

  .chatUserNameIcon {
    margin-top: 9px;
  }
}

/* Styles for devices with a minimum width of 1025 pixels (typical desktop devices) */
@media only screen and (min-width: 1025px) {
  /* .chatInput {
    position: sticky;
    flex: 0 0 100px;
    padding-top: 12px;
    padding-bottom: 2px;
     padding-left: 24px;
    padding-right: 24px; 
    width: 98%;
     max-width: 1028px; 
    margin-bottom: 50px; 
    margin-top: 8px;
  } */
  .chatMessageStream {
    padding-left: 24px;
    padding-right: 24px;
  }

  .chatUserNameIcon {
    margin-top: 9px;
  }
}

.selectedIndex {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px #4f7d6c;
}

.sysSampleQue {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
  cursor: pointer;
}

.sysSampleQue:hover {
  background-color: #fff;
  color: #000;
}

.lineSeperator {
  border-bottom: 1px solid #d1d1d1;
}

.samData {
  margin: 18px;
}

.activeIndex {
  background-color: red;
}

.helpmessage {
  font-weight: 700;
  font-size: 26px;
}

.helpmeicon {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -155px -202px;
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
}

.gramerlyicon {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -344px -134px;
  width: 4px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.gramerlyPopover {
  background: #000000b8;
  box-shadow: 0px 0px 6px 0px #00000040;
  color: #ffffff !important;
  width: 462px;
  /* height: 525px; */
  border-radius: 15px;
}

.gramerlyPopover ::after {
  border-top-color: #000000b8 !important;
  bottom: auto !important;
}

.samQue {
  border-bottom: 1px solid #d1d1d1;
}

.singleQue {
  padding: 4px;
}

.singleQue:hover {
  background-color: #f2f2f2;
  color: #000;
}

.bottomDiv {
}

.chatcontainer {
  width: 100%;
}

.chatInput {
  margin-top: 16px;
  padding: 0 10% 0 15%;
}

.chatIpWhole {
  position: fixed;
  bottom: 2%;
  width: 100%;
  left: 0;
}

.chatIpHalf {
  position: fixed;
  bottom: 2%;
  width: calc(100% - 25%);
  left: 0;
}

.gridcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.chatEmptyState {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 0px;
  padding: 0 15%;
}

.ServiceText {
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.selectedIndSerTxt {
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.chatInputContainer {
  width: 100%;
}

.chatBox {
  height: 67vh;
  overflow: auto;
  margin-top: 10px;
}
.ServiceText {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0px 10% 0px 15%;
}
.defaultSampleQuestion {
  padding-top: 15px !important;
  min-width: 85%;
  text-align: center;
}
.sampleQuestionCard {
  height: 100%;
}

/* // 67 */
@media screen and (min-width: 1821px) and (max-width: 3000px) {
  .ServiceText {
    min-height: 46%;
  }

  .selectedIndSerTxt {
    min-height: 60%;
  }

  .chatBox {
    height: 65vh;
  }
}

/* // 75% */
@media screen and (min-width: 1701px) and (max-width: 1820px) {
  .ServiceText {
    min-height: 40%;
  }

  .selectedIndSerTxt {
    min-height: 55%;
  }

  .chatBox {
    height: 65vh;
  }
}

/* // 80% */
@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .ServiceText {
    min-height: 50%;
  }

  .selectedIndSerTxt {
    min-height: 50%;
  }

  .chatBox {
    height: 60vh;
  }
}

/* // 90 % */
@media screen and (min-width: 1401px) and (max-width: 1599px) {
  .ServiceText {
    min-height: 17%;
  }
  .helpmeicon {
    zoom: 0.7;
  }
  .helpmessage {
    font-size: 21px;
  }

  .selectedIndSerTxt {
    min-height: 38%;
  }

  .chatBox {
    height: 56vh;
  }
}

/* // 100% */
@media screen and (min-width: 1264px) and (max-width: 1400px) {
  .ServiceText {
    min-height: 17%;
  }
  .helpmeicon {
    zoom: 0.7;
  }
  .helpmessage {
    font-size: 21px;
  }

  .selectedIndSerTxt {
    min-height: 27%;
  }

  .chatBox {
    height: 55vh;
  }

  .sample_question_description {
    font-size: 13px;
    line-height: 23.25px;
    letter-spacing: 0em;
    text-align: left;
    color: #787878;
  }
}

.chatCnt {
  width: 100%;
}

.mobileSampQueCnt {
}

.mobileSampQueDropDown {
  margin: 8px 0px;
}

.mobileChatEmptyState {
  padding: 0 2%;
}

.mobileHelpmsg {
  font-size: 14px;
}

.modalCloseIcon {
  display: flex;
  background-position: -272px -90px !important;
  width: 28px;
  height: 28px;
}

.ModalCloseBtn {
  cursor: pointer;
  position: absolute !important;
  z-index: 1;
  right: -10px !important;
  top: -9px;
}

.selectedIndexMainDiv {
  padding: 0 10%;
}

.promptMainDiv {
  display: flex;
  flex-direction: column;
}
.disclaimer {
  color: #7d7d7d;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
