.container {
  background-image: url(../../assets/images/HomeBackground.svg) !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed; /* Optional: If you want the background image to stay fixed while scrolling */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  text-align: center;
  padding-bottom: 11% !important;
}
.home_title {
  font-size: 45px;
  font-weight: 700 !important;
  line-height: 65px;
  letter-spacing: 0em;
  margin-bottom: 25px;
}
.home_subtitle_left {
  background: linear-gradient(
    90deg,
    #ff80e5 0%,
    #ff8db9 25%,
    #ff9f82 50%,
    #ffad58 75%
  );
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 35px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
}
.home_subtitle_right {
  color: #000000;

  font-size: 35px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
}
.home_description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
.app.dark {
  .home_getstarted_btn {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 18px;
    background: #fff !important;
    color: #000000 !important;
    border-radius: 0px !important;
    border: 0px !important;
  }
}

.home_getstarted_btn {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 18px;
  background: #222 !important;
  color: #fff !important;
  border-radius: 0px !important;
  border: 0px !important;
}

.genAIChatIcon {
  background: url("../../assets/images/Chat-logo.svg") no-repeat;
  width: 129px;
  height: 106px;
  position: absolute;
  bottom: 25px;
  right: 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
